import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { FigmaEmbed } from './common/FigmaEmbed';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "работа-с-дизайном"
    }}>{`Работа с дизайном`}</h1>
    <p>{`Дизайн-система распространяется как библиотека в Figma.
Для ее использования вы можете либо сохранить себе файл как копию (Duplicate to your Drafts),
либо опубликовать его как командную библиотеку (Team Library).`}</p>
    <p>{`Мы рекомендуем вариант с командной библиотекой.
Так вы сможете автоматически получать свежие обновления и использовать компоненты в нескольких проектах.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library"
      }}>{`Ссылка на Figma`}</a></p>
    <p>{`Подробная инструкция по работе с библиотекой Figma представлена внутри файла.`}</p>
    <FigmaEmbed node={'%3Fnode-id%3D0%253A1'} mdxType="FigmaEmbed" />
    <p>{`Кроме основного файла с компонентами также доступны:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/file/raJ1JI1lfRDJ0ctGj7Rpgf/hse_icons_library?node-id=1955%3A901"
        }}>{`Файл с иконками`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/file/h3HKVyYAAF2Q2dMvgCZyM1/hse_assets?node-id=0%3A1"
        }}>{`Файл с ассетами`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      